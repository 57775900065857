<template>
  <div class="flex flex-col w-full h-full items-center">
    <el-input placeholder="输入合集名"
      v-model="filter.collectionName"
      clearable
      @change="onFilter"
      size="large"
    >
      <template #prefix>
        <el-icon class="el-input__icon"><search /></el-icon>
      </template>
    </el-input>

    <ul v-infinite-scroll="onLoad" class="infinite-list">
      <li v-for="c in collections" :key="c.id" class="infinite-list-item"
        @click="onCollection(c)"
      >
        <span class="truncate">{{ c.collection_name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {useRouter} from 'vue-router'
import * as store from './store'
import {Search} from '@element-plus/icons-vue'

export default {
  components: {
    Search,
  },
  setup() {
    const router = useRouter();
    const {collections} = store.useStore();
    const filter = store.state.filter;

    const onLoad = store.actions.getCollections;

    const onFilter = () => {
      store.mutations.resetCollections();
      return onLoad();
    };

    const onCollection = (c) => {
      router.push(`/works/${c.id}`).catch(() => {});
    };

    return {
      collections,
      filter,
      onLoad,
      onFilter,
      onCollection,
    }
  },
}
</script>

<style scoped>
.infinite-list {
  @apply w-full;
  @apply h-full;
  @apply flex-1;
  @apply flex;
  @apply flex-col;
  @apply items-center;
  @apply justify-start;
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list-item {
  @apply flex-1;
  @apply flex;
  @apply flex-row;
  @apply items-center;
  @apply justify-center;
  @apply w-full;
  @apply text-2xl;
  @apply bg-orange-300;
  @apply my-4;
  @apply py-3;
}
.infinite-list-item:hover {
  @apply cursor-pointer;
  @apply bg-orange-800;
  @apply text-white;
}
</style>